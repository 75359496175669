import React from "react";
import { css, ThemeProvider } from "styled-components";

export const mediaQuery = (
  cssProp = "padding", // the CSS property to apply to the breakpoints
  cssPropUnits = "px", // the units of the CSS property (can set equal to "" and apply units to values directly)
  values = [], // array of objects, e.g. [{ 800: 60 }, ...] <-- 800 (key) = screen breakpoint, 60 (value) = CSS prop breakpoint
  mediaQueryType = "min-width" // media query breakpoint type, i.e.: max-width, min-width, max-height, min-height
) => {
  const breakpointProps = values.reduce((mediaQueries, value) => {
    const [screenBreakpoint, cssPropBreakpoint] = [Object.keys(value)[0], Object.values(value)[0]];
    return (mediaQueries += `
    @media screen and (${mediaQueryType}: ${screenBreakpoint}px) {
      ${cssProp}: ${cssPropBreakpoint}${cssPropUnits};
    }
    `);
  }, "");
  return css([breakpointProps]);
};

const theme = {
  colors: {
    byBlack: "#000000",
    politikBlack: "#1d1d1b",
    politikWhite: "#fef4e9",
    iceBlue: "#a2d2df",
    red: "#d53127",
    green: "#558758"
  },
  fonts: {
    header: "'Pirata One', sans-serif",
    primary: "'Lexend Deca', sans-serif",
    reross: "'reross-quadratic', sans-serif",
    futura: "'futura-pt', sans-serif"
  },
  fontWeights: {
    thin: 100,
    futuraBook: 400,
    futuraMedium: 500,
    futuraBold: 700,
    reross: 400
  },
  transitions: {
    standard: "all 0.2s ease-in-out"
  }
};

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
